@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Montserrat, system-ui, sans-serif;
    font-size: 15px;
  }
}

/* Scrollbar Base Styles */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

/* Dark mode override */
@media (prefers-color-scheme: dark) {
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  /* Firefox dark mode */
  * {
    scrollbar-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.05);
  }
}